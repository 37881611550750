import React, { useState } from "react"
import { graphql, useStaticQuery, Link, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import styled from "styled-components"
import { Container, Row, Col, useScreenClass } from "react-grid-system"
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from "react-netlify-forms"
import { useForm, Controller } from "react-hook-form"
import { makeStyles } from "@material-ui/core/styles"
import {
  Paper,
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  Button,
} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectLabel: {
    bottom: "1.7em",
    top: "inherit",
    [theme.breakpoints.down("sm")]: {
      bottom: "2.4em",
    },
  },
}))

const Wrapper = styled.div`
  min-width: 100%;
  padding-bottom: 68px;
  width: 100%;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}) {
    padding-bottom: 20px;
    padding-top: 20px;
  }
`

const CtaButton = styled(Button)`
  background: ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.sansSerif};
`

const ContactForm = (props) => {
  const classes = useStyles()
  const location = useLocation()
  const screenClass = useScreenClass()

  const {
    control,
    errors,
    formState: { isSubmitting },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm()

  const netlify = useNetlifyForm({
    name: "contact-form",
    action: "/thank-you/",
    honeypotName: "bot-field",
    onSuccess: (response, context) => {
      // reset()
      thanks()
    },
  })

  const [state, setState] = useState({
    location: location,
    pathName: location.pathname,
    showConditional: false,
    submitted: false,
  })

  const onSubmit = (data) => {
    logFieldValues()
    setSubmitted(true)
    netlify.handleSubmit(null, data)
  }

  const setConditional = (bool) => {
    setState({
      ...state,
      showConditional: bool,
    })
  }

  const setSubmitted = (submitted = true) => {
    setState({
      ...state,
      submitted: submitted,
    })
  }

  const thanks = () => {
    navigate("/thank-you/", {
      state: { ...state },
    })
  }

  const services = [
    {
      label: "Brand Development or Strategy",
      value: "Brand Development or Strategy",
      conditional: true,
    },
    {
      label: "Website Design / Development",
      value: "Website Design / Development",
      conditional: true,
    },
    {
      label: "Digital Marketing / Lead Gen",
      value: "Digital Marketing / Lead Gen",
      conditional: true,
    },
    {
      label: "Video / Creative Production",
      value: "Video / Creative Production",
      conditional: true,
    },
    {
      label: "Social Media",
      value: "Social Media",
      conditional: true,
    },
    {
      label: "Business Consulting",
      value: "Business Consulting",
      conditional: false,
    },
    {
      label: "Other",
      value: "Other",
      conditional: false,
    },
  ]

  // debug
  const logFieldValues = () => {
    const values = getValues()
    console.log("Field Values:", values)
  }

  const showForm = (
    <NetlifyFormProvider {...netlify}>
      <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
        <Honeypot />
        <Paper square elevation={3} style={{ padding: 50 }}>
          <Grid container alignItems="flex-start" spacing={6}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <Controller
                  as={
                    <TextField
                      onChange={(event) => {
                        return event
                      }}
                    >
                      {services.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          data-show-conditional={option.conditional}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  control={control}
                  defaultValue=""
                  error={!!errors.service}
                  fullWidth
                  helperText={!!errors.service ? "Field is required." : ""}
                  label="What are you looking for? *"
                  name="service"
                  onChange={(event) => {
                    setConditional(
                      event[0].nativeEvent.target.dataset.showConditional ==
                        "true"
                        ? true
                        : false
                    )
                    return event[0].target.value // return the Selected Field Value
                  }}
                  rules={{ required: true }}
                  select
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={<TextField />}
                control={control}
                error={!!errors.details}
                fullWidth
                helperText={!!errors.details ? "Field is required." : ""}
                inputProps={{ "aria-label": "Details?" }}
                inputRef={register}
                label="Can you provide us with some details? *"
                multiline
                name="details"
                rows={4}
                maxRows={4}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={<TextField />}
                control={control}
                error={!!errors.firstName}
                fullWidth
                helperText={!!errors.firstName ? "Field is required." : ""}
                inputProps={{ "aria-label": "First Name" }}
                inputRef={register}
                label="First Name *"
                name="firstName"
                rules={{ required: true }}
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={<TextField />}
                control={control}
                error={!!errors.lastName}
                fullWidth
                helperText={!!errors.lastName ? "Field is required." : ""}
                inputProps={{ "aria-label": "Last Name" }}
                inputRef={register}
                label="Last Name *"
                name="lastName"
                rules={{ required: true }}
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={<TextField />}
                control={control}
                error={!!errors.phone}
                fullWidth
                helperText={!!errors.phone ? "Field is required." : ""}
                inputProps={{ "aria-label": "Phone" }}
                inputRef={register}
                label="Phone *"
                name="phone"
                rules={{ required: true }}
                type="tel"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={<TextField />}
                control={control}
                error={!!errors.company}
                fullWidth
                helperText={!!errors.company ? "Field is required." : ""}
                inputProps={{ "aria-label": "Company" }}
                inputRef={register}
                label="Company *"
                name="company"
                rules={{ required: true }}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={<TextField />}
                control={control}
                error={!!errors.email}
                fullWidth
                helperText={!!errors.email ? "Field is required." : ""}
                inputProps={{ "aria-label": "Email" }}
                inputRef={register({
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                label="Email *"
                name="email"
                rules={{ required: true }}
                type="email"
              />
            </Grid>
            <Grid item>
              <CtaButton
                type="submit"
                size="large"
                variant="contained"
                disabled={isSubmitting}
              >
                Submit
              </CtaButton>
            </Grid>
          </Grid>
        </Paper>
      </NetlifyFormComponent>
    </NetlifyFormProvider>
  )

  return (
    <Wrapper className={props.className}>
      <Container
        style={{
          paddingLeft: ["md", "lg", "xl"].includes(screenClass)
            ? "3rem"
            : "1.5625rem",
          paddingRight: ["md", "lg", "xl"].includes(screenClass)
            ? "3rem"
            : "1.5625rem",
        }}
      >
        <Row align="center" justify="center">
          <Col xl={10}>
            {errors && errors.submit}
            {showForm}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default ContactForm
